<template>
    <div>
        <core-toolbar :drawer="drawer" @toggle-drawer="toggleDrawer" style="z-index:100;"></core-toolbar>
        <core-drawer :drawer="drawer" @toggle-drawer="toggleDrawer"></core-drawer>
        <router-view :key="$route.fullPath" name="router_wrapper_cpanel"></router-view>
    </div>
</template>

<script>
    export default {
        name: "CPanel",
        data: () => ({
            drawer: true,
        }),
        methods:{
            toggleDrawer (){
                this.drawer = !this.drawer;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/cpanel/index.scss';
</style>
